<template>
    <div>
        <form>
            <div class="form-group">
                <div class="mb-4 ">
                    <label>Email Address (Username) <span class="required-indc">*</span> </label>                
                    <input type="text" class="reg-field" placeholder="eg: email@domainname.com">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Password <span class="required-indc">*</span></label>                
                    <input type="password" class="reg-field" placeholder="Password">
                    <span class="short-msg">Minimum 8 characters, at least one letter, one number & one special character</span>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Confirm Password <span class="required-indc">*</span></label>                
                    <input type="password" class="reg-field" placeholder="Confirm Password">
                </div>
            </div>

        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/contact-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/address-info" class="button-blue">Next</router-link>
                <router-link to="/registration/address-info" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BrokerForm'
}
</script>