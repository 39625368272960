<template>
    <div class="regInfo-wrap text-center">
        <div class="status-idc">
            <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li class="active">4</li>
            </ul>
            <span class="seperator"></span>
        </div>
        <div class="regInfo-icon">
            <img src="../../../assets/images/registration/user-data.svg" alt="" class="img-fluid">
        </div>
        <h2>Group/Admin Login Information</h2>
        <p>Fill out the form on the left <br>
            You can always editi the data <br>
            in the setting menu.</p>
        <span class="seperator"></span>
        <ul class="mt-2">
            <li>(567) 318 4668</li>
            <li>info@purecodeinc.com</li>
        </ul>
    </div>
</template>
<script>
export default {
    name:'BrokerSideInfo'
}
</script>